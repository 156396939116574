import { validate as validateArn } from "@aws-sdk/util-arn-parser";
export function validateBucketNameMiddleware() {
    return (next) => async (args) => {
        const { input: { Bucket }, } = args;
        if (typeof Bucket === "string" && !validateArn(Bucket) && Bucket.indexOf("/") >= 0) {
            const err = new Error(`Bucket name shouldn't contain '/', received '${Bucket}'`);
            err.name = "InvalidBucketName";
            throw err;
        }
        return next({ ...args });
    };
}
export const validateBucketNameMiddlewareOptions = {
    step: "initialize",
    tags: ["VALIDATE_BUCKET_NAME"],
    name: "validateBucketNameMiddleware",
    override: true,
};
export const getValidateBucketNamePlugin = (unused) => ({
    applyToStack: (clientStack) => {
        clientStack.add(validateBucketNameMiddleware(), validateBucketNameMiddlewareOptions);
    },
});
