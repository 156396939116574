import { EventStreamCodec } from "@aws-sdk/eventstream-codec";
import { getChunkedStream } from "./getChunkedStream";
import { getUnmarshalledStream } from "./getUnmarshalledStream";
export class EventStreamMarshaller {
    constructor({ utf8Encoder, utf8Decoder }) {
        this.eventStreamCodec = new EventStreamCodec(utf8Encoder, utf8Decoder);
        this.utfEncoder = utf8Encoder;
    }
    deserialize(body, deserializer) {
        const chunkedStream = getChunkedStream(body);
        const unmarshalledStream = getUnmarshalledStream(chunkedStream, {
            eventStreamCodec: this.eventStreamCodec,
            deserializer,
            toUtf8: this.utfEncoder,
        });
        return unmarshalledStream;
    }
    serialize(input, serializer) {
        const self = this;
        const serializedIterator = async function* () {
            for await (const chunk of input) {
                const payloadBuf = self.eventStreamCodec.encode(serializer(chunk));
                yield payloadBuf;
            }
            yield new Uint8Array(0);
        };
        return {
            [Symbol.asyncIterator]: serializedIterator,
        };
    }
}
