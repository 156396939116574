import { ChecksumAlgorithm } from "./constants";
import { CLIENT_SUPPORTED_ALGORITHMS } from "./types";
export const getChecksumAlgorithmForRequest = (input, { requestChecksumRequired, requestAlgorithmMember }) => {
    if (!requestAlgorithmMember || !input[requestAlgorithmMember]) {
        return requestChecksumRequired ? ChecksumAlgorithm.MD5 : undefined;
    }
    const checksumAlgorithm = input[requestAlgorithmMember];
    if (!CLIENT_SUPPORTED_ALGORITHMS.includes(checksumAlgorithm)) {
        throw new Error(`The checksum algorithm "${checksumAlgorithm}" is not supported by the client.` +
            ` Select one of ${CLIENT_SUPPORTED_ALGORITHMS}.`);
    }
    return checksumAlgorithm;
};
